import { postMuon, returnBarcodes } from '@app/api/muon.api';
import { getMemberById } from '@app/api/thanhvien.api';
import { Button, Col, Form, Input, message, Modal, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import { useState } from 'react';

type Props = {
  isReturnModalVisible: boolean;
  toggleShowReturnModal: () => void;
};

function ReturnModal(props: Props) {
  const [isClicked, setIsClicked] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    const transformedValues = {
      Barcodes: values.Barcodes.split(',').map((barcode: string) => barcode.trim()),
    };

    try {
      setIsClicked(true);
      const response = await returnBarcodes(transformedValues);
      props.toggleShowReturnModal();
      message.success('Danh mục sách đã được thêm thành công.');
      form.resetFields();
      setIsClicked(false);
      window.location.reload();
    } catch (error) {
      console.log(error);
      setIsClicked(false);
    }
  };

  return (
    <Modal
      title="Trả sách"
      open={props.isReturnModalVisible}
      onCancel={() => {
        form.resetFields();
        props.toggleShowReturnModal();
      }}
      footer={null}
      style={{ top: 20 }}
      centered
      width={800}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label="Barcode Sách được mượn (Mỗi sách nhau bởi dấu phẩy)"
              name="Barcodes"
              rules={[{ required: true, message: 'Vui lòng không bỏ trống!' }]}
            >
              <Input.TextArea autoSize={{ minRows: 1, maxRows: 6 }} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item style={{ marginTop: '20px' }}>
          <Button
            style={{ width: '100%' }}
            type="primary"
            htmlType="submit"
            size="middle"
            disabled={isClicked}
            loading={isClicked}
          >
            Xác Nhận Trả Các Barcode Này
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ReturnModal;
