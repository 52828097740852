import { Book } from '@app/api/book.api';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { Loading } from '@app/components/common/Loading/Loading';
import { Modal } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import Title from 'antd/lib/typography/Title';

type Props = {
  selectedBook: Book | null;
  isModalVisible: boolean;
  onHandleCancel: () => void;
  columns: ColumnsType<any>;
};

const LABELS = [
  'Id sách',
  'Tên sách',
  'Tác giả',
  'Nhà xuất bản',
  'Năm xuất bản',
  'Thể loại',
  'Số lượng sách có sẵn',
  'Số lượng sách đang mượn',
];

function DetailModal(props: Props) {
  return (
    <Modal
      title={`${props.selectedBook && props.selectedBook.BookName}`}
      open={props.isModalVisible}
      onCancel={props.onHandleCancel}
      footer={null}
      style={{ top: 20 }}
      width={800}
    >
      {props.selectedBook ? (
        <BaseRow gutter={[0, 20]}>
          <BaseCol span={9} style={{ fontWeight: 'bold' }}>
            {LABELS.map((label) => (
              <p key={label}>{label}</p>
            ))}
          </BaseCol>
          <BaseCol span={1} style={{ fontWeight: 'bold' }}>
            {LABELS.map((label, index) => (
              <p key={label + index}>:</p>
            ))}
          </BaseCol>
          <BaseCol span={14}>
            <p>{props.selectedBook.BookId}</p>
            <p>{props.selectedBook.BookName}</p>
            <p>{props.selectedBook.Authors.join(' - ')}</p>
            <p>{props.selectedBook.Publisher || 'Không xác định'}</p>
            <p>{props.selectedBook.PublishYear}</p>
            <p>{props.selectedBook.Category}</p>
            <p>{props.selectedBook.Available} (quyển)</p>
            <p>{props.selectedBook.OnLoan} (quyển)</p>
          </BaseCol>
          <BaseCol span={24}>
            <Title level={5}>Danh sách ({props.selectedBook.BookName})</Title>
            <BaseTable columns={props.columns} dataSource={props.selectedBook.Items || []} pagination={false} />
          </BaseCol>
        </BaseRow>
      ) : (
        <Loading />
      )}
    </Modal>
  );
}

export default DetailModal;
