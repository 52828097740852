/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from '../../DashboardPages/DashboardPage.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { Button, PageHeader, Tabs } from 'antd';
import { PlusOutlined, RetweetOutlined } from '@ant-design/icons';
import TabPane from 'antd/lib/tabs/TabPane';
import '../Pages.css';
import { ThongTinMuonTable } from '@app/components/tables/BasicTable/ThongTinMuonTable';
import { LichSuMuonTable } from '@app/components/tables/BasicTable/LichSuMuonTable';
import { ThatLacTable } from '@app/components/tables/BasicTable/ThatLacTable';
import moment from 'moment';
import CreateModal from './CreateModal';
import ReturnModal from './ReturnModal';

const MuonSach: React.FC = () => {
  const { isDesktop } = useResponsive();
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [isReturnModalVisible, setIsReturnModalVisible] = useState(false);

  const toggleShowCreateModal = () => {
    setIsCreateModalVisible(!isCreateModalVisible);
  };

  const toggleShowReturnModal = () => {
    setIsReturnModalVisible(!isReturnModalVisible);
  };

  const desktopLayout = (
    <BaseRow>
      <S.LeftSideCol>
        <BaseRow gutter={[60, 60]}>
          <BaseCol span={24}>
            <div className="container">
              <PageHeader
                className="page-header"
                title="Thông tin Mượn Sách/Thiết bị"
                footer="Thông tin chi tiết của người mượn"
                extra={[
                  <Button key="return-btn" type="primary" icon={<RetweetOutlined />} onClick={toggleShowReturnModal}>
                    Trả sách
                  </Button>,
                  <Button key="1" type="primary" icon={<PlusOutlined />} onClick={toggleShowCreateModal}>
                    Thêm Người Mượn
                  </Button>,
                ]}
              />
              <Tabs defaultActiveKey="1">
                <TabPane tab="Thông tin mượn sách" key="1">
                  <ThongTinMuonTable />
                </TabPane>
                {/* <TabPane tab="Lịch sử mượn sách" key="2">
                  <LichSuMuonTable />
                </TabPane>
                <TabPane tab="Quản lý thất lạc" key="3">
                  <ThatLacTable />
                </TabPane> */}
              </Tabs>
            </div>
          </BaseCol>
        </BaseRow>
      </S.LeftSideCol>
    </BaseRow>
  );

  const mobileAndTabletLayout = (
    <BaseRow gutter={[20, 24]}>
      <BaseCol span={24}>
        <div className="container">
          <PageHeader
            className="page-header"
            title="Thông tin Mượn Sách/Thiết bị"
            footer="Thông tin chi tiết của người mượn"
            extra={[
              <Button key="return-btn" type="primary" icon={<RetweetOutlined />} onClick={toggleShowReturnModal}>
                Trả sách
              </Button>,
              <Button key="loan-btn" type="primary" icon={<PlusOutlined />} onClick={toggleShowCreateModal}>
                Mượn sách
              </Button>,
            ]}
          />
          <Tabs defaultActiveKey="1">
            <TabPane tab="Thông tin mượn sách" key="1">
              <ThongTinMuonTable />
            </TabPane>
            <TabPane tab="Lịch sử mượn sách" key="2">
              <LichSuMuonTable />
            </TabPane>
            <TabPane tab="Quản lý thất lạc" key="3">
              <ThatLacTable />
            </TabPane>
          </Tabs>
        </div>
      </BaseCol>
    </BaseRow>
  );

  return (
    <>
      <PageTitle>Muon</PageTitle>
      {isDesktop ? desktopLayout : mobileAndTabletLayout}
      <CreateModal isModalVisible={isCreateModalVisible} toggleShowModal={toggleShowCreateModal} />
      <ReturnModal isReturnModalVisible={isReturnModalVisible} toggleShowReturnModal={toggleShowReturnModal} />
    </>
  );
};

export default MuonSach;
