/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useCallback } from 'react';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { ColumnsType } from 'antd/es/table';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import { useMounted } from '@app/hooks/useMounted';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { EyeOutlined } from '@ant-design/icons';
import { Modal, Form, Input, Row, Col } from 'antd';
import { getMuon, getMuonById, Muon } from '@app/api/muon.api';
import { InputSearch } from '@app/components/header/components/HeaderSearch/HeaderSearch.styles';
import './style.css';
import { Loading } from '@app/components/common/Loading/Loading';
import moment from 'moment';
import { Label } from '@app/components/nft-dashboard/Balance/components/TopUpBalanceForm/TopUpBalanceForm.styles';

export const ThongTinMuonTable: React.FC = () => {
  const [tableData, setTableData] = useState<{ data: Muon[]; loading: boolean }>({
    data: [],
    loading: false,
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedMuon, setSelectedMuon] = useState<Muon | null>(null);
  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const [showOverdue, setShowOverdue] = useState(false);
  const [showAll, setShowAll] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  const fetch = useCallback(() => {
    setTableData((tableData) => ({ ...tableData, loading: true }));
    getMuon({ current: 1, pageSize: 10 }).then((res) => {
      if (isMounted.current) {
        const currentDate = new Date();
        const updatedData = res.data.map((item) => {
          const dueDate = new Date(item.DueDate);
          return {
            ...item,
            isOverdue: !isNaN(dueDate.getTime()) && dueDate < currentDate,
          };
        });
        setTableData({ data: updatedData, loading: false });
      }
    });
  }, [isMounted]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const handleTableChange = () => {
    fetch();
  };

  const handleDeleteRow = (rowId: any) => {
    setTableData((prevTableData) => ({
      ...prevTableData,
      data: prevTableData.data.filter((item) => item.Barcode !== rowId),
    }));
  };

  const showModal = async (Barcode: any) => {
    setIsModalVisible(true);
    setTableData((prevState) => ({ ...prevState, loading: true }));

    try {
      const barcode = await getMuonById(Barcode);
      setSelectedMuon(barcode);
    } catch (error) {
      console.error('Error fetching loan data:', error);
    } finally {
      setTableData((prevState) => ({ ...prevState, loading: false }));
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedMuon(null);
  };

  const handleSubmit = (values: unknown) => {
    console.log('Received values:', values);
    setIsModalVisible(false);
  };

  const formatDateToVN = (dateString: string | number | Date) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('vi-VN');
  };

  const calculateBorrowingPeriod = (loanDate: string, dueDate: string) => {
    const loan = moment(loanDate);
    const due = moment(dueDate);
    return due.diff(loan, 'days');
  };

  const modalChitiet = (
    <Modal
      title={`${selectedMuon?.MemberName}`}
      open={isModalVisible}
      onCancel={handleCancel}
      footer={null}
      width={800}
      style={{ top: 20 }}
    >
      {selectedMuon ? (
        <Form layout="horizontal" onFinish={handleSubmit}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Họ và tên:" name="MemberName">
                <Label style={{ color: 'black', fontWeight: 'bold' }}>{selectedMuon.MemberName}</Label>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Mã thành viên:" name="MemberId">
                <Label style={{ color: 'black', fontWeight: 'bold' }}>{selectedMuon.MemberId}</Label>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Số điện thoại:" name="PhoneNumber">
                <Label style={{ color: 'black', fontWeight: 'bold' }}>{selectedMuon.PhoneNumber}</Label>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Barcode Sách/Thiết bị được mượn:" name="Barcode">
                <Label style={{ color: 'black', fontWeight: 'bold' }}>{selectedMuon.Barcode}</Label>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Vị trí:" name="Location">
                <Label style={{ color: 'black', fontWeight: 'bold' }}>{selectedMuon.Location}</Label>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Ngày mượn:" name="LoanDate">
                <Label style={{ color: 'black', fontWeight: 'bold' }}>{formatDateToVN(selectedMuon.LoanDate)}</Label>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Ngày trả:" name="DueDate">
                <Label style={{ color: 'black', fontWeight: 'bold' }}>{formatDateToVN(selectedMuon.DueDate)}</Label>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Thời hạn mượn:" name="ReturnDate">
                <Label style={{ color: 'black', fontWeight: 'bold' }}>
                  {calculateBorrowingPeriod(selectedMuon.LoanDate, selectedMuon.DueDate)} ngày
                </Label>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ) : (
        <Loading />
      )}
    </Modal>
  );

  const columns: ColumnsType<Muon> = [
    {
      title: t('common.name'),
      dataIndex: 'MemberName',
    },
    {
      title: t('common.thongtinsach'),
      dataIndex: 'BookName',
    },
    {
      title: t('common.sdt'),
      dataIndex: 'PhoneNumber',
      align: 'center',
      width: '10%',
    },
    {
      title: t('common.thoigianmuon'),
      dataIndex: 'LoanDate',
      align: 'center',
      width: '10%',
      render: (record) => new Date(record).toLocaleDateString('vi-VN'),
    },
    {
      title: 'Hạn trả',
      dataIndex: 'DueDate',
      align: 'center',
      width: '10%',
      render: (record) => new Date(record).toLocaleDateString('vi-VN'),
    },
    {
      title: t('common.thoigiantra'),
      dataIndex: 'ReturnDate',
      align: 'center',
      width: '10%',
      render: (record) => (record ? new Date(record).toLocaleDateString('vi-VN') : 'Chưa trả sách'),
    },
    {
      title: t('tables.actions'),
      dataIndex: 'actions',
      align: 'center',
      width: '10%',
      render: (text, record) => (
        <BaseSpace>
          <BaseButton type="link" onClick={() => showModal(record.Barcode)}>
            <EyeOutlined />
          </BaseButton>
          {/* <BaseButton type="link" danger onClick={() => handleDeleteRow(record.Barcode)}>
            {t('tables.xuly')}
          </BaseButton> */}
        </BaseSpace>
      ),
    },
  ];

  const handleShowAll = () => {
    setShowAll(true);
    setShowOverdue(false);
  };

  const handleFilterOverdue = () => {
    setShowOverdue(!showOverdue);
    setShowAll(false);
  };

  const filteredData = tableData.data
    .filter((item) => (showAll ? true : showOverdue ? item.isOverdue : true))
    .filter(
      (item) =>
        String(item.MemberId).toLowerCase().includes(searchTerm.toLowerCase()) ||
        String(item.MemberName).toLowerCase().includes(searchTerm.toLowerCase()) ||
        String(item.DueDate).includes(searchTerm),
    );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div style={{ background: 'white', borderRadius: '10px', margin: '0 20px 20px 20px', padding: '0 10px' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
          height: '80px',
        }}
      >
        <BaseButton onClick={handleShowAll}>
          {t('Tất cả')} ({tableData.data.length})
        </BaseButton>
        <BaseButton onClick={handleFilterOverdue} style={{ marginLeft: '10px' }}>
          <span style={{ borderRadius: '5px', backgroundColor: '#bf2c24', color: '#bf2c24', marginRight: '5px' }}>
            qh
          </span>
          {t('tables.quahan')} ({tableData.data.filter((item) => item.isOverdue).length})
        </BaseButton>
        <div style={{ display: 'flex', marginLeft: 'auto' }}>
          <InputSearch
            style={{ width: '350px', marginRight: '10px' }}
            placeholder={t('Tìm kiếm theo ID, Họ tên, Hạn trả')}
            onChange={handleSearch}
          />
        </div>
      </div>
      <BaseTable
        columns={columns}
        dataSource={filteredData}
        pagination={{ pageSize: 10 }}
        loading={tableData.loading}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
        bordered
        rowClassName={(record) => (record.isOverdue ? 'overdue-row' : '')}
      />
      {modalChitiet}
    </div>
  );
};
