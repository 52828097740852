import React, { useEffect, useState, useCallback } from 'react';
import { BasicTableRow, getBasicTableData, Pagination, Tag } from 'api/table.api';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { ColumnsType } from 'antd/es/table';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import { defineColorByPriority } from '@app/utils/utils';
import { notificationController } from 'controllers/notificationController';
import { Status } from '@app/components/profile/profileCard/profileFormNav/nav/payments/paymentHistory/Status/Status';
import { useMounted } from '@app/hooks/useMounted';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { EditOutlined, EyeOutlined, LockOutlined } from '@ant-design/icons';
import { HeaderSearch } from '@app/components/header/components/HeaderSearch/HeaderSearch';
import { Button, Col, DatePicker, Form, Input, Modal, Row, Select, Table, TableColumnsType, Upload } from 'antd';
import { DanhMuc, getDanhMuc, getDanhMucById } from '@app/api/danhmuc.api';
import { InputSearch } from '@app/components/header/components/HeaderSearch/HeaderSearch.styles';
import { Loading } from '@app/components/common/Loading/Loading';
import { Label } from '@app/components/nft-dashboard/Balance/components/TopUpBalanceForm/TopUpBalanceForm.styles';
import { Category, getCategories } from '@app/api/category.api';

export const DanhMucTable: React.FC = () => {
  const [tableData, setTableData] = useState<{ data: Category[]; loading: boolean }>({
    data: [],
    loading: false,
  });

  const [selectedDanhMuc, setSelectedDanhMuc] = useState<Category | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const [form] = Form.useForm();
  const [searchTerm, setSearchTerm] = useState('');

  const fetch = useCallback(() => {
    setTableData((tableData) => ({ ...tableData, loading: true }));
    getCategories({ current: 1, pageSize: 10 }).then((res) => {
      if (isMounted.current) {
        setTableData({ data: res.data, loading: false });
      }
    });
  }, [isMounted]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const handleTableChange = (pagination: Pagination) => {
    fetch();
  };

  // const handleDeleteRow = (rowId: number) => {
  //   setTableData({
  //     ...tableData,
  //     data: tableData.data.filter((item) => item.key !== rowId),
  //     pagination: {
  //       ...tableData.pagination,
  //       total: tableData.pagination.total ? tableData.pagination.total - 1 : tableData.pagination.total,
  //     },
  //   });
  // };

  const columns: TableColumnsType<Category> = [
    {
      title: t('common.iddanhmuc'),
      dataIndex: 'CategoryId',
      width: '10%',
      align: 'center',
    },
    {
      title: t('common.tendanhmuc'),
      dataIndex: 'CategoryName',
    },
    {
      title: t('common.soluongsach'),
      dataIndex: 'BookCount',
      width: '10%',
      align: 'right',
    },
    {
      title: 'Code',
      dataIndex: 'CategoryCode',
      width: '10%',
      align: 'center',
    },
    {
      title: t('common.capnhatganday'),
      dataIndex: 'UpdatedAt',
      width: '10%',
      align: 'center',
      render: (value) => new Date(value).toLocaleDateString('vi-VN'),
    },
    {
      title: t('common.nguoicapnhat'),
      dataIndex: 'UpdatedBy',
    },
    // {
    //   title: t('tables.actions'),
    //   dataIndex: 'actions',
    //   width: '15%',
    //   render: (value, record) => {
    //     return (
    //       <BaseSpace>
    //         <BaseButton type="link" onClick={() => showModal()}>
    //           <EyeOutlined />
    //         </BaseButton>
    //         <BaseButton type="link" danger>
    //           <LockOutlined />
    //         </BaseButton>
    //       </BaseSpace>
    //     );
    //   },
    // },
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSubmit = (values: unknown) => {
    console.log('Received values:', values);
    setIsModalVisible(false);
  };

  const modalChitiet = (
    <Modal
      title={`Chi tiết danh mục: ${selectedDanhMuc?.CategoryName}`}
      open={isModalVisible}
      onCancel={handleCancel}
      footer={null}
      style={{ top: 20 }}
      width={800}
    >
      {selectedDanhMuc ? (
        <Form layout="vertical">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="ID Danh mục" name="CategoryId">
                <Label style={{ color: 'black', fontWeight: 'bold' }}>{selectedDanhMuc.CategoryId}</Label>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Tên Danh mục" name="CategoryId">
                <Label style={{ color: 'black', fontWeight: 'bold' }}>{selectedDanhMuc.CategoryName}</Label>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Số lượng sách" name="BookCount">
                <Label style={{ color: 'black', fontWeight: 'bold' }}>{selectedDanhMuc.BookCount}</Label>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Vị trí" name="Locations">
                <Label style={{ color: 'black', fontWeight: 'bold' }}>
                  {selectedDanhMuc?.Locations
                    ? selectedDanhMuc.Locations.map((location: { LocationName: string }) => location.LocationName).join(
                        ', ',
                      )
                    : 'N/A'}
                </Label>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ) : (
        <Loading />
      )}
    </Modal>
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const filteredData = tableData.data.filter(
    (item) =>
      String(item.CategoryCode).toLowerCase().includes(searchTerm.toLowerCase()) ||
      String(item.CategoryName).toLowerCase().includes(searchTerm.toLowerCase()) ||
      String(item.CategoryId).toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <div style={{ background: 'white', borderRadius: '10px', margin: '20px', padding: '0 10px' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
          height: '80px',
        }}
      >
        <InputSearch
          style={{ width: '350px', marginRight: '10px' }}
          placeholder={t('Tìm kiếm theo ID, Tên, Code')}
          onChange={handleSearch}
        />
      </div>
      <BaseTable
        columns={columns}
        dataSource={filteredData}
        pagination={{ pageSize: 10 }}
        loading={tableData.loading}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
        bordered
      />
      {modalChitiet}
    </div>
  );
};
