/* eslint-disable react-hooks/exhaustive-deps */
import { Book } from '@app/api/book.api';
import { postMuon } from '@app/api/muon.api';
import { getMemberById } from '@app/api/thanhvien.api';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { Loading } from '@app/components/common/Loading/Loading';
import { Button, Col, Form, Input, message, Modal, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { ColumnsType } from 'antd/lib/table';
import Title from 'antd/lib/typography/Title';
import moment from 'moment';
import { useEffect, useState } from 'react';

type Props = {
  isModalVisible: boolean;
  toggleShowModal: () => void;
};

const date = new Date(new Date().setDate(new Date().getDate() + 7));
const formattedDate =
  String(date.getDate()).padStart(2, '0') +
  '-' +
  String(date.getMonth() + 1).padStart(2, '0') +
  '-' +
  date.getFullYear();

const SEVEN_DAYS_LATER = formattedDate;
console.log(SEVEN_DAYS_LATER);

function CreateModal(props: Props) {
  const [isClicked, setIsClicked] = useState(false);
  const [form] = Form.useForm();
  const [memberId, setMemberId] = useState('');
  const [debouncedTerm, setDebouncedTerm] = useState('');

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedTerm(form.getFieldValue('MemberId'));
    }, 500);

    return () => {
      clearTimeout(timerId);
    };
  }, [memberId]);

  useEffect(() => {
    if (debouncedTerm) {
      const fetchResults = async () => {
        try {
          const memberInfo = await getMemberById(memberId);
          form.setFieldsValue({
            MemberName: memberInfo.MemberName,
            PhoneNumber: memberInfo.PhoneNumber,
          });
          setMemberId(form.getFieldValue('MemberId'));
        } catch (error) {
          console.error('Lỗi khi lấy thông tin thành viên:', error);
        }
      };
      fetchResults();
    }
  }, [debouncedTerm]);

  // const handleMemberIdChange = async (value: string) => {
  //   try {
  //     const memberInfo = await getMemberById(value);
  //     form.setFieldsValue({
  //       MemberName: memberInfo.MemberName,
  //       PhoneNumber: memberInfo.PhoneNumber,
  //     });
  //     setMemberId(form.getFieldValue('MemberId'));
  //   } catch (error) {
  //     console.error('Lỗi khi lấy thông tin thành viên:', error);
  //   }
  // };

  const handleDateChange = (value: string) => {
    if (value.length > 10) return;

    const numbers = value.replace(/[^0-9]/g, '');

    let formattedValue = '';

    if (numbers.length >= 2) {
      formattedValue += numbers.slice(0, 2);
      if (numbers.length > 2) {
        formattedValue += '-' + numbers.slice(2, 4);
        if (numbers.length > 4) {
          formattedValue += '-' + numbers.slice(4, 8);
        }
      }
    } else {
      formattedValue = numbers;
    }

    form.setFieldsValue({ DueDate: formattedValue });
  };

  const onFinish = async (values: any) => {
    try {
      setIsClicked(true);
      const transformedValues = {
        ...values,
        MemberId: values.MemberId,
        LoanDate: moment().format('YYYY-MM-DD'),
        DueDate: values.DueDate ? moment(values.DueDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        Barcodes: values.Barcodes.split(',').map((barcode: string) => barcode.trim()),
      };
      console.log(transformedValues);

      const response = await postMuon(transformedValues);
      props.toggleShowModal();
      message.success('Danh mục sách đã được thêm thành công.');
      form.resetFields();
      setIsClicked(false);
      window.location.reload();
    } catch (error: any) {
      setIsClicked(false);
    }
  };

  const handleBarcodeChange = async (value: string) => {
    try {
      const barcodes = value.split(',').map((barcode) => barcode.trim());

      console.log(barcodes);

      // const bookDetails = await Promise.all(
      //   barcodes.map(async (barcode) => {
      //     const response = await getBookById(barcode);
      //     return response;
      //   }),
      // );

      // form.setFieldsValue({
      //   Location: bookDetails.map((detail) => detail.Location).join(', '),
      // });
    } catch (error) {
      console.error('Lỗi khi lấy thông tin sách:', error);
    }
  };

  return (
    <Modal
      title="Thêm Người Mượn"
      open={props.isModalVisible}
      onCancel={() => {
        form.resetFields();
        props.toggleShowModal();
      }}
      footer={null}
      style={{ top: 20 }}
      width={800}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Họ và Tên" name="MemberName">
              <Input disabled style={{ backgroundColor: '#EEEEEE', color: 'black' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Mã Thành Viên"
              name="MemberId"
              rules={[{ required: true, message: 'Vui lòng không bỏ trống!' }]}
            >
              {/* <Input onChange={(e) => handleMemberIdChange(e.target.value)} /> */}
              <Input onChange={(e) => setMemberId(e.target.value)} value={memberId} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Số điện thoại" name="PhoneNumber">
              <Input disabled style={{ backgroundColor: '#EEEEEE', color: 'black' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Ngày Trả (Mặc định 7 ngày từ ngày mượn)"
              name="DueDate"
              rules={[{ required: true, message: 'Vui lòng chọn ngày trả!' }]}
              initialValue={SEVEN_DAYS_LATER}
            >
              <Input onChange={(e) => handleDateChange(e.target.value)} maxLength={10} placeholder="DD/MM/YYYY" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Barcode Sách được mượn (Mỗi sách nhau bởi dấu phẩy)"
              name="Barcodes"
              rules={[{ required: true, message: 'Vui lòng không bỏ trống!' }]}
            >
              <Input.TextArea
                autoSize={{ minRows: 1, maxRows: 6 }}
                onChange={(e) => handleBarcodeChange(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Ghi chú" name="Notes" initialValue={null}>
              <TextArea autoSize={{ minRows: 4, maxRows: 10 }} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item style={{ marginTop: '20px' }}>
          <Button
            style={{ width: '100%' }}
            type="primary"
            htmlType="submit"
            size="middle"
            disabled={isClicked}
            loading={isClicked}
          >
            Xác Nhận Thêm Người Mượn
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default CreateModal;
