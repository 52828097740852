import React, { useEffect, useState, useCallback } from 'react';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { ColumnsType } from 'antd/es/table';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import { useMounted } from '@app/hooks/useMounted';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { EyeOutlined, LockOutlined } from '@ant-design/icons';
import { Select, TableColumnsType } from 'antd';
import { Book, getBooks, getBiblioById } from '@app/api/book.api';
import { InputSearch } from '@app/components/header/components/HeaderSearch/HeaderSearch.styles';
import DetailModal from '@app/pages/CustomPages/Book/DetailModal';

const modalColumns: TableColumnsType<Book> = [
  {
    key: 'biblio-detail-#',
    title: '#',
    render: (text, record, index) => index + 1,
    align: 'center',
    width: '5%',
  },
  {
    key: 'biblio-detail-barcode',
    title: 'Barcode',
    dataIndex: 'Barcode',
    align: 'center',
  },
  {
    key: 'biblio-detail-location',
    title: 'Vị trí',
    dataIndex: 'Location',
    align: 'center',
    render: (record) => (record.length > 0 ? record : 'Không có'),
  },
];

export const ThongTinTable: React.FC = () => {
  const [tableData, setTableData] = useState<{ data: Book[]; loading: boolean }>({
    data: [],
    loading: false,
  });
  // const [categories, setCategories] = useState<{ data: Category[]; loading: boolean }>({
  //   data: [],
  //   loading: false,
  // });
  // const [selectedCategory, setSelectedCategory] = useState<string>('Tất cả');

  // const [locations, setLocations] = useState<{ data: Location[]; loading: boolean }>({
  //   data: [],
  //   loading: false,
  // });
  // const [selectedLocation, setSelectedLocation] = useState<string>('Tất cả');

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedBook, setSelectedBook] = useState<Book | null>(null);
  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const [searchTerm, setSearchTerm] = useState('');
  const [categoryOptions, setCategoryOptions] = useState<string[]>([]);
  // const [locationOptions, setLocationOptions] = useState<string[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>('Default');
  // const [selectedLocation, setSelectedLocation] = useState<string>('Default');

  const fetch = useCallback(() => {
    setTableData((tableData) => ({ ...tableData, loading: true }));
    getBooks({ current: 1, pageSize: 10 }).then((res) => {
      if (isMounted.current) {
        const books = res.data;

        const categories = Array.from(new Set(books.map((book) => book.Category)));
        // const locations = Array.from(new Set(books.map((book) => book.Location)));

        setCategoryOptions(categories);
        // setLocationOptions(locations);

        setTableData({ data: books, loading: false });
      }
    });
  }, [isMounted]);

  // const fetchCategories = useCallback(() => {
  //   setCategories((tableData) => ({ ...tableData, loading: true }));
  //   getCategories({ current: 1, pageSize: 10 }).then((res) => {
  //     if (isMounted.current) {
  //       setCategories({ data: res.data, loading: false });
  //     }
  //   });
  // }, [isMounted]);

  // const fetchLocations = useCallback(() => {
  //   setLocations((tableData) => ({ ...tableData, loading: true }));
  //   getlocations({ current: 1, pageSize: 10 }).then((res) => {
  //     if (isMounted.current) {
  //       setLocations({
  //         data: res.data,
  //         loading: false,
  //       });
  //     }
  //   });
  // }, [isMounted]);

  // useEffect(() => {
  //   fetch();
  //   fetchCategories();
  //   fetchLocations();
  // }, [fetch, fetchCategories, fetchLocations]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const handleTableChange = () => {
    fetch();
  };

  const handleDeleteRow = (rowId: number) => {
    setTableData((prevTableData) => ({
      ...prevTableData,
      data: prevTableData.data.filter((item) => item.BookId !== rowId),
    }));
  };

  const showModal = async (bookId: number) => {
    setIsModalVisible(true);
    setTableData((prevState) => ({ ...prevState, loading: true }));

    try {
      const book = await getBiblioById(bookId);
      setSelectedBook(book);
    } catch (error) {
      console.error('Error fetching book data:', error);
    } finally {
      setTableData((prevState) => ({ ...prevState, loading: false }));
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedBook(null);
  };

  const columns: ColumnsType<Book> = [
    {
      key: 'biblio-id',
      title: t('common.IDsach'),
      dataIndex: 'BookId',
      width: '10%',
      align: 'right',
    },
    {
      key: 'book-name',
      title: t('common.tensach'),
      dataIndex: 'BookName',
    },
    {
      key: 'biblio-authors',
      title: t('common.tacgia'),
      dataIndex: 'Authors',
      render: (value) => value.join(' - '),
    },
    {
      key: 'biblio-publish-year',
      title: t('common.namxb'),
      dataIndex: 'PublishYear',
      width: '10%',
      align: 'right',
    },
    {
      key: 'biblio-category',
      title: t('Danh mục'),
      dataIndex: 'Category',
      width: '10%',
    },
    {
      key: 'biblio-avaiable',
      title: 'Sách có sẵn',
      dataIndex: 'Available',
      width: '10%',
      align: 'right',
    },
    {
      key: 'biblio-actions',
      title: t('tables.actions'),
      dataIndex: 'actions',
      width: '10%',
      align: 'center',
      render: (text, record) => {
        return (
          <BaseSpace>
            <BaseButton type="link" onClick={() => showModal(record.BookId)}>
              <EyeOutlined style={{ padding: 0 }} />
            </BaseButton>
            {/* <BaseButton type="default" danger onClick={() => handleDeleteRow(record.BookId)}>
              <LockOutlined style={{ padding: 0, background: 'translate' }} />
            </BaseButton> */}
          </BaseSpace>
        );
      },
    },
  ];

  const filteredData = tableData.data
    .filter(
      (item) => selectedCategory === 'Default' || item.Category === selectedCategory, // Nếu `item.CategoryId` là ID của danh mục
    )
    .filter(
      (item) =>
        String(item.BookId).toLowerCase().includes(searchTerm.toLowerCase()) ||
        String(item.BookName).toLowerCase().includes(searchTerm.toLowerCase()),
    );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleCategoryChange = (value: string) => {
    setSelectedCategory(value);
  };

  return (
    <div style={{ background: 'white', borderRadius: '10px', margin: '20px', padding: '0 10px' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '80px',
        }}
      >
        <div
          style={{
            marginRight: 'auto',
          }}
        >
          <Select
            defaultValue="Danh mục"
            style={{ width: 170, borderRadius: '8px' }}
            bordered={true}
            dropdownStyle={{ borderRadius: '8px' }}
            onChange={handleCategoryChange}
          >
            <Select.Option value="Default">Tất cả</Select.Option>
            {categoryOptions.map((category) => (
              <Select.Option key={category} value={category}>
                {category}
              </Select.Option>
            ))}
          </Select>
          {/* <Select
            defaultValue={selectedCategory}
            style={{ width: 120, borderRadius: '8px' }}
            bordered={true}
            dropdownStyle={{ borderRadius: '8px' }}
            onChange={(value) => setSelectedCategory(value)}
          >
            {categories.data.map((category, index) => (
              <Select.Option value={category.CategoryId} key={category + index.toString()}>
                {category.CategoryName}
              </Select.Option>
            ))}
          </Select>

          <Select
            defaultValue={selectedLocation}
            style={{ width: 120, marginLeft: '10px', borderRadius: '8px' }}
            bordered={true}
            dropdownStyle={{ borderRadius: '8px' }}
            onChange={(value) => setSelectedLocation(value)}
          >
            {locations.data.map((location, index) => (
              <Select.Option value={location.LocationId} key={location + index.toString()}>
                {location.LocationId}
              </Select.Option>
            ))}
          </Select> */}
        </div>
        <div style={{ display: 'flex', marginLeft: 'auto' }}>
          <InputSearch
            style={{ width: '350px', marginRight: '10px' }}
            placeholder={t('Tìm kiếm theo ID, Tên sách, Vị trí')}
            onChange={handleSearch}
          />
        </div>
      </div>
      <BaseTable
        columns={columns}
        dataSource={filteredData}
        pagination={{ pageSize: 10 }}
        loading={tableData.loading}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
        bordered
      />
      <DetailModal
        selectedBook={selectedBook}
        isModalVisible={isModalVisible}
        onHandleCancel={handleCancel}
        columns={modalColumns}
      />
    </div>
  );
};
