import React, { useCallback, useEffect, useState } from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from '../DashboardPages/DashboardPage.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { Button, Col, Form, Input, message, Modal, PageHeader, Row, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ViTriTable } from '@app/components/tables/BasicTable/ViTriTable';
import './Pages.css';
import { postViTri } from '@app/api/vitri.api';
import { Category, getCategories } from '@app/api/category.api';
import { useMounted } from '@app/hooks/useMounted';
const ViTri: React.FC = () => {
  const [Categories, setCategories] = useState<{ data: Category[]; loading: boolean }>({
    data: [],
    loading: false,
  });
  const [selectedCategory, setSelectedCategory] = useState<string>('Chọn danh mục');

  const { isDesktop } = useResponsive();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const { isMounted } = useMounted();

  const fetch = useCallback(() => {
    setCategories((tableData) => ({ ...tableData, loading: true }));
    getCategories({ current: 1, pageSize: 10 }).then((res) => {
      if (isMounted.current) {
        setCategories({ data: res.data, loading: false });
      }
    });
  }, [isMounted]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const onFinish = async (values: any) => {
    try {
      const transformedValues = {
        ...values,
      };

      console.log('Received values from form: ', transformedValues);
      const response = await postViTri(transformedValues);
      form.resetFields();
      console.log('API response:', response);
      message.success('Vị trí đã được thêm thành công.');
      setIsModalVisible(false);
      window.location.reload();
    } catch (error: any) {
      if (error.response) {
        console.error('API Error:', error.response.data);
        message.error(`Có lỗi xảy ra: ${error.response.data}`);
      } else {
        console.error('Error submitting form:', error.message);
        message.error(`Có lỗi xảy ra: ${error.message}`);
      }
    }
  };

  const modalThem = (
    <Modal
      title="Thêm Vị Trí"
      open={isModalVisible}
      onCancel={handleCancel}
      footer={null}
      style={{ top: 20 }}
      width={800}
    >
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Danh mục"
              name="CategoryId"
              rules={[{ required: true, message: 'Vui lòng không bỏ trống!' }]}
            >
              <Select value={selectedCategory} defaultValue={selectedCategory} onChange={(e) => setSelectedCategory(e)}>
                <Select.Option value={selectedCategory}>Danh mục</Select.Option>
                {Categories.data.map((category) => (
                  <Select.Option key={category.CategoryId + category.CategoryCode} value={category.CategoryId}>
                    {category.CategoryName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Tên vị trí"
              name="LocationName"
              rules={[{ required: true, message: 'Vui lòng không bỏ trống!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item style={{ marginTop: '20px' }}>
          <Button style={{ width: '100%' }} type="primary" htmlType="submit" size="middle">
            Xác Nhận Thêm Vị Trí
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );

  const desktopLayout = (
    <>
      <BaseRow>
        <S.LeftSideCol>
          <BaseRow gutter={[60, 60]}>
            <BaseCol span={24}>
              <div className="container">
                <PageHeader
                  className="page-header"
                  title="Vị trí Sách/Thiết bị"
                  footer="Thông tin vị trí Sách/Thiết bị"
                  extra={[
                    <Button key="1" type="primary" icon={<PlusOutlined />} onClick={showModal}>
                      Thêm Vị Trí
                    </Button>,
                  ]}
                />
                <ViTriTable />
              </div>
            </BaseCol>
          </BaseRow>
        </S.LeftSideCol>
      </BaseRow>
    </>
  );

  const mobileAndTabletLayout = (
    <BaseRow gutter={[20, 24]}>
      <BaseCol span={24}>
        <div className="container">
          <PageHeader
            className="page-header"
            title="Vị trí Sách/Thiết bị"
            footer="Thông tin vị trí Sách/Thiết bị"
            extra={[
              <Button key="1" type="primary" icon={<PlusOutlined />} onClick={showModal}>
                Thêm Vị Trí
              </Button>,
            ]}
          />
          <ViTriTable />
        </div>
      </BaseCol>
    </BaseRow>
  );

  return (
    <>
      <PageTitle>ViTri</PageTitle>
      {isDesktop ? desktopLayout : mobileAndTabletLayout}
      {modalThem}
    </>
  );
};

export default ViTri;
