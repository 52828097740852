import React, { useState } from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from '../DashboardPages/DashboardPage.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { Button, Col, Form, Input, message, Modal, PageHeader, Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { DanhMucTable } from '@app/components/tables/BasicTable/DanhMucTable';
import './Pages.css';
import { postDanhMuc } from '@app/api/danhmuc.api';
const DanhMuc: React.FC = () => {
  const { isDesktop } = useResponsive();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const onFinish = async (values: any) => {
    try {
      const transformedValues = {
        ...values,
      };

      console.log('Received values form: ', transformedValues);
      const response = await postDanhMuc(transformedValues);
      setIsModalVisible(false);
      form.resetFields();
      console.log('API response:', response);
      message.success('Danh mục đã được thêm thành công.');
      window.location.reload();
    } catch (error: any) {
      if (error.response) {
        console.error('API Error:', error.response.data);
        message.error(`Có lỗi xảy ra: ${error.response.data}`);
      } else {
        console.error('Error submitting form:', error.message);
        message.error(`Có lỗi xảy ra: ${error.message}`);
      }
    }
  };

  const modalThem = (
    <Modal
      title="Thêm Vị Trí"
      visible={isModalVisible}
      onCancel={handleCancel}
      footer={null}
      style={{ top: 20 }}
      width={800}
    >
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Tên danh mục"
              name="CategoryName"
              rules={[{ required: true, message: 'Vui lòng không bỏ trống!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Mã danh mục"
              name="CategoryCode"
              rules={[{ required: true, message: 'Vui lòng không bỏ trống!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item style={{ marginTop: '20px' }}>
          <Button style={{ width: '100%' }} type="primary" htmlType="submit" size="middle">
            Xác Nhận Thêm Danh Mục
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );

  const desktopLayout = (
    <>
      <BaseRow>
        <S.LeftSideCol>
          <BaseRow gutter={[60, 60]}>
            <BaseCol span={24}>
              <div className="container">
                <PageHeader
                  className="page-header"
                  title="Danh mục Sách/Thiết bị"
                  footer="Thông tin danh mục Sách/Thiết bị"
                  extra={[
                    <Button key="1" type="primary" icon={<PlusOutlined />} onClick={showModal}>
                      Thêm Danh Mục
                    </Button>,
                  ]}
                />
                <DanhMucTable />
              </div>
            </BaseCol>
          </BaseRow>
        </S.LeftSideCol>
      </BaseRow>
    </>
  );

  const mobileAndTabletLayout = (
    <BaseRow gutter={[20, 24]}>
      <BaseCol span={24}>
        <div className="container">
          <PageHeader
            className="page-header"
            title="Danh mục Sách/Thiết bị"
            footer="Thông tin danh mục Sách/Thiết bị"
            extra={[
              <Button key="1" type="primary" icon={<PlusOutlined />} onClick={showModal}>
                Thêm Danh Mục
              </Button>,
            ]}
          />
          <DanhMucTable />
        </div>{' '}
      </BaseCol>
    </BaseRow>
  );

  return (
    <>
      <PageTitle>DanhMuc</PageTitle>
      {isDesktop ? desktopLayout : mobileAndTabletLayout}
      {modalThem}
    </>
  );
};

export default DanhMuc;
