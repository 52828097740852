/* eslint-disable react/jsx-key */
import React from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from '../../DashboardPages/DashboardPage.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { ThongTinTable } from '@app/components/tables/BasicTable/ThongTinTable';
import { Button, PageHeader } from 'antd';
import '../Pages.css';

const ThongTin: React.FC = () => {
  const { isDesktop } = useResponsive();

  const desktopLayout = (
    <>
      <BaseRow>
        <S.LeftSideCol>
          <BaseRow gutter={[60, 60]}>
            <BaseCol span={24}>
              <div className="container">
                <PageHeader
                  className="page-header"
                  title="Quản lý Sách/Thiết bị"
                  footer="Thông tin Sách/Thiết bị"
                  extra={[<Button />]}
                />
                <ThongTinTable />
              </div>
            </BaseCol>
          </BaseRow>
        </S.LeftSideCol>
      </BaseRow>
    </>
  );

  const mobileAndTabletLayout = (
    <BaseRow gutter={[20, 24]}>
      <BaseCol span={24}>
        <div className="container">
          <PageHeader
            className="page-header"
            title="Quản lý Sách/Thiết bị"
            footer="Thông tin Sách/Thiết bị"
            extra={[<Button />]}
          />
          <ThongTinTable />
        </div>
      </BaseCol>
    </BaseRow>
  );

  return (
    <>
      <PageTitle>ThongTin</PageTitle>
      {isDesktop ? desktopLayout : mobileAndTabletLayout}
    </>
  );
};

export default ThongTin;
