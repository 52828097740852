/* eslint-disable @typescript-eslint/no-explicit-any */
import { APIResponse, httpApi } from './http.api';
import { Pagination } from './table.api';

export interface Biblio {
  BookId: number;
  BookName: string;
  PublishYear: string;
  PublisherName: string;
  Available: number;
  Authors: string[];

  Thumbnail: string;
  Category: string;
  CategoryId: number;
  Items: [
    {
      Barcode: string;
      Location: string;
    },
  ];
  Onloan: number;
  OnLoan: number;
  CreatedAt: string;
  Publisher: string;
  IsLocked: number;
}

export interface BiblioResponse {
  data: Biblio[];
  pagination: Pagination;
}

export const getBiblio = async (pagination: Pagination) => {
  const books: Biblio[] = await httpApi.get<APIResponse>('/book/getBiblio').then((res) => res.data.data);
  console.log(books);
  return {
    data: books,
    pagination: { ...pagination, total: books.length },
  };
};

export const getBiblioById = async (BookId: string) => {
  const book: Biblio = await httpApi
    .get<APIResponse>(`/book/getBiblioById`, {
      params: {
        BookId: BookId,
      },
    })
    .then((res) => res.data.data);
  console.log(book);
  return book;
};

export const getBiblioByKeyword = async (Keyword: string) => {
  const book: Biblio[] = await httpApi
    .get<APIResponse>(`/book/getByKeyword`, {
      params: {
        Keyword: Keyword,
      },
    })
    .then((res) => res.data.data);
  console.log(book);
  return book;
};

export const putLock = async (BookId: number) => {
  const putLock: Biblio = await httpApi
    .put<APIResponse>(`/book/lock-biblio`, {
      BookId: BookId,
    })
    .then((res) => res.data.data);
  console.log(putLock);
  return putLock;
};

export const putUnlock = async (BookId: number) => {
  const putUnlock: Biblio = await httpApi
    .put<APIResponse>(`/book/unlock-biblio`, {
      BookId: BookId,
    })
    .then((res) => res.data.data);
  console.log(putUnlock);
  return putUnlock;
};

interface UpdateBiblio {
  BookId: number;
  ISBN: string;
  BookName: string;
  AuthorName: string;
  PublisherName: string;
  PublishYear: number;
  Thumbnail: string;
  CategoryId: number;
  Notes: string;
}

export const putUpdate = async (data: FormData) => {
  const putUpdate: UpdateBiblio = await httpApi
    .post<APIResponse>(`/book/update-biblio?_method=PUT`, data)
    .then((res) => res.data.data);
  console.log(putUpdate);
  return putUpdate;
};
